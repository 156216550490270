import React, {useState} from 'react';
import './App.css';
import {SpringState, SpringStateContext} from "./context";
import {AccessFormWrapper} from "./Pages/acces-form";
import {Route, Routes} from "react-router-dom";
import {WorkshopsWrapper} from "./Pages/workshops.jsx";
import {MeetTheCompanies} from "./meet";
import {ParticipantProfileWrapper} from "./Pages/participant-profile";
import {LoginWrapper} from "./Pages/login";
import {Countdown} from "./Pages/countdown";
import Navbar2 from "./components/navbar2";
import TextGdpr from './components/TextGdpr';
import {ForgotPassword} from './Pages/forgotPassword';

const defaultState = {
    config: {
        id: "0",
        email: "",
        first_name: "",
        name: "",
        phone: "",
        university: "",
        study_year: "",
        password: "",
        admin: false,
        partner:false
    },
    login: {
        email: "",
        password: ""
    },
    invalidFields: new Map(),
}

export function App() {
    const [state, setState] = useState(defaultState)
    const [navVisible, showNavbar] = useState(false);
    const [isNavbarPage, setIsNavbarPage] = useState(false)
    return (
        <div style={{minHeight: '100vh', height: 'fit-content'}}>
        <SpringContext.Provider value={{state, setState, setIsNavbarPage}}>
            {isNavbarPage ? <Navbar2 visible={navVisible} show={showNavbar}/>: null}
            <Routes>
                <Route path={'/'} element={<Countdown/>}/>

                {/* <Route path={'/'} element={<LoginWrapper/>}/>
                {/*<Route path={'/register'} element={<AccessFormWrapper/>}/>
                <Route path={'/workshops'} element={<WorkshopsWrapper/>}/> 
               <Route path={'/meet'} element={<MeetTheCompanies/>}/>
                <Route path={'/profile'} element={<ParticipantProfileWrapper/>}/>
                <Route path={'/recover/:idUser/:token'} element={<ForgotPassword/>}/>
                <Route path={'*'} element={<div>404</div>}/>
                <Route path={'/gdpr'} element={<TextGdpr/>}/> 
                 */}
            </Routes>
        </SpringContext.Provider>
        </div>
    );
}

export const SpringContext = React.createContext({
    state: defaultState, setState: (value) => {
    }, setIsNavbarPage: (value) => {}
})
