import React, { useEffect, useRef, useState } from 'react';
import '../style/Countdown.css'
import {CountDownCard} from './countdown-card';

const CountDownTimer = () => {
    //card ref
    const SecondsCardRef = useRef(null);
    const MinutesCardRef = useRef(null);
    const HoursCardRef = useRef(null);
    const DaysCardRef = useRef(null);
    //state

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    // const [distance, setDistance] = useState(0)
    // console.log('distance2', distance)
            const  countDownDate = new Date("Mar 04, 2025 08:00:00").getTime();
            const now = new Date().getTime();

            const distance = countDownDate - now

    useEffect(() => {
            // setDistance(countDownDate - now)
            setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))) ;
            setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))) ;
            setSeconds(Math.floor((distance % (1000 * 60)) / 1000)) ;
    }, [distance])

    useEffect(() => {
        seconds === 0 && setSeconds(59);
        minutes === 0 && setMinutes(59);
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1);
                SecondsCardRef.current?.classList.toggle('rotate');
            }, 1000);
        }
        if (seconds === 0 && minutes > 0) {
            setMinutes(minutes - 1);
            MinutesCardRef.current?.classList.toggle('rotate');
        }
    }, [seconds, minutes]);
    useEffect(() => {
        hours === 0 && setHours(23);
        if (minutes === 0 && hours > 0) {
            setHours(hours - 1);
            HoursCardRef.current?.classList.toggle('rotate');
        }
    }, [minutes, hours]);
    useEffect(() => {
        if(hours === 0) {
            setDays(days-1)
            DaysCardRef.current?.classList.toggle('rotate');
        }
    }, [hours, days]);
    return (
        <div className="countdown__container">
            <CountDownCard
                label="zile"
                number={days}
                cardRef={DaysCardRef}
            />
            <CountDownCard
                label="ore"
                number={hours}
                cardRef={HoursCardRef}
            />
            <CountDownCard
                label="minute"
                number={minutes}
                cardRef={MinutesCardRef}
            />
            <CountDownCard
                label="secunde"
                number={seconds}
                cardRef={SecondsCardRef}
            />
        </div>
    );
};

export default CountDownTimer;