import CountDownTimer from "../components/countdown-timer";
import '../style/Countdown.css'
import Logo from '../assets/spring-full-alb.png'

export function Countdown (){

    return <div className={'page-countdown'}>
        <div className="app-container">
            <div className="container">
                <main>
                    <h2 className="countdown-titlu">Înscrierile încep în curând!</h2>
                    <CountDownTimer />
                </main>
                <img className="countdown-logo" src={Logo} alt={'logo'}/>

            </div>
        </div>
    </div>
}